body {
}

table {
    border-collapse: collapse;
}

table td, table th {
    border: 1px black solid;
}
